import React from 'react';
import Routes from './Routes';
import './App.scss'

function App() {
  return(
    <Routes/>
  );
}

export default App;
